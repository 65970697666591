import React from 'react'
import { graphql } from 'gatsby'

import { DefaultLayout } from '../layouts/defaultlayout'
import { GalleryWrapper, Container, Divider, YouTubeFrame } from '../components'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ProjectsWithImagesFragment, LogoFragment } from '../fragments'

import { Navigation } from '../pages/_navigation'

export const query = graphql`
  query($slug: String!) {
    project: directus {
      ...ProjectsWithImagesFragment
    }
    logo: directus {
      ...LogoFragment
    }
  }
`

export default ({ data }: any) => {
  const {logo, project} = data
  return (
    <DefaultLayout>
      <Navigation logo={logo.logo.logo.imageUrl} />
      <Container>
        <h1>{project.projects[0].title}</h1>
        {project.projects[0].youtube && (
          <>
            <YouTubeFrame ytid={project.projects[0].youtube} />
            <Divider />
          </>
        )}
        <div dangerouslySetInnerHTML={{ __html: project.projects[0].text }} />
        <Divider />
        <GalleryWrapper images={project.projects[0].images} />
      </Container>
    </DefaultLayout>
  )
}
